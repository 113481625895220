<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="报溢单ID" >
                <el-input v-model="search.baoYiDanID" size="small" />
            </x-search-item>
            <x-search-item label="报溢时间" textWidth="138px" hasEnd>
                <el-date-picker v-model="search.baoYiSJBegin" size="small" type="datetime" />
                <el-date-picker v-model="search.baoYiSJEnd" size="small" type="datetime" slot="end"/>
            </x-search-item>
            <x-search-item label="报溢人" >
                <el-input v-model="search.baoYiRen" size="small" />
            </x-search-item>
            <x-search-item label="主要事项" >
                <el-input v-model="search.title" size="small" />
            </x-search-item>
            <x-search-item label="审核状态" >
                <x-selector-one v-model="search.auditStatus" size="small" dictType="T_AUDIT_STATUS"/>
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border>
            <el-table-column width="50" label="序号" type="index" fixed/>
            <el-table-column width="180" prop="baoYiDanID" label="报溢单ID" />
            <el-table-column width="180" prop="baoYiSJ" label="报溢时间" />
            <el-table-column width="180" prop="baoYiShangPinZhongShu" label="报溢商品种数" />
            <el-table-column width="180" prop="baoYiZongJinE" label="报溢总金额" />
            <el-table-column width="180" prop="baoYiRen" label="报溢人" />
            <el-table-column width="180" prop="baoYiRenID" label="报溢人ID" />
            <el-table-column width="180" prop="beiZhu" label="备注" />
            <el-table-column width="180" prop="title" label="主要事项" />
            <el-table-column width="180" label="审核状态">
                <x-dict-show slot-scope="{row}" :code="row.auditStatus" dictType="T_AUDIT_STATUS" />
            </el-table-column>
            <el-table-column width="100" align="center" label="操作" fixed="right">
                <el-button slot-scope="{row}" type="primary" size="mini" round @click="handleToDetail(row.id)">查看</el-button>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 详情页 -->
        <detail :detail="detail"/>
    </div>
</template>
<script>
    import {query} from "@/service/kcgl/BaoYiXX";
    import XTableBase from "@/components/x/XTableBase";
    import Detail from "@/view/kcgl/bygl/BaoYiXXDetail";

    export default {
        name: "BaoYiXXQuery",
        mixins: [XTableBase],
        components: {Detail},
        data() {
            this.refreshService = query;
            return {
                search: {
                    baoYiDanID: '',
                    baoYiSJBegin: '',
                    baoYiSJEnd: '',
                    baoYiRen: '',
                    title: '',
                    auditStatus: '',
                }
            }
        }
    }
</script>

<style scoped>
</style>