import request from '@/util/request'

//基础列表
export function list(data) {
    return request({
        url: '/bao-yi-xx/list',
        method: 'post',
        data
    })
}

//添加保存
export function add(data) {
    return request({
        url: '/bao-yi-xx/add',
        method: 'post',
        data
    })
}

//获取编辑页数据
export function getUpdate(id) {
    return request({
        url: `/bao-yi-xx/get-update/${id}`,
        method: 'get',
    })
}

//获取详情页数据
export function getDetail(id) {
    return request({
        url: `/bao-yi-xx/get-detail/${id}`,
        method: 'get',
    })
}

//编辑保存
export function update(data) {
    return request({
        url: '/bao-yi-xx/update',
        method: 'post',
        data
    })
}

//删除
export function del(id) {
    return request({
        url: `/bao-yi-xx/delete/${id}`,
        method: 'post',
    })
}

//批量删除
export function deleteBatch(data) {
    return request({
        url: '/bao-yi-xx/delete-batch',
        method: 'post',
        data
    })
}

//独立查询
export function query(data) {
    return request({
        url: '/bao-yi-xx/query',
        method: 'post',
        data
    })
}

//审核列表
export function auditList(data) {
    return request({
        url: '/bao-yi-xx/audit-list',
        method: 'post',
        data
    })
}

//获取审核内容
export function getAudit(id) {
    return request({
        url: `/bao-yi-xx/get-audit/${id}`,
        method: 'get',
    })
}

//审核
export function audit(data) {
    return request({
        url: '/bao-yi-xx/audit',
        method: 'post',
        data
    })
}

/*
列表路由
{
    path: 'bao-yi-xx-list',
    component: () => import('@/view/kcgl/bygl/BaoYiXXList'),
    name: 'BaoYiXXList',
    meta: {title: '报溢信息', isTag: true, isMenu: true, searchable: true}
}

审核列表路由
{
    path: 'bao-yi-xx-audit-list',
    component: () => import('@/view/kcgl/bygl/BaoYiXXAuditList'),
    name: 'BaoYiXXAuditList',
    meta: {title: '报溢信息审核', isTag: true, isMenu: true, searchable: true}
}

独立查询路由
{
    path: 'bao-yi-xx-query',
    component: () => import('@/view/kcgl/bygl/BaoYiXXQuery'),
    name: 'BaoYiXXQuery',
    meta: {title: '报溢信息查询', isTag: true, isMenu: true, searchable: true}
}

提醒列表
{
    path: 'bao-yi-xx-remind-list',
    component: () => import('@/view/kcgl/bygl/BaoYiXXRemindList'),
    name: 'BaoYiXXRemindList',
    meta: {title: '报溢信息提醒', isTag: true, isMenu: true, searchable: true}
}

菜单识别:基础相关-list为入口
[
{"name":"报溢信息基础列表","methodUrl":"/bao-yi-xx/list","operateType":"search","sort":1,"functionClassification":"0","_key":"1"},
{"name":"报溢信息添加保存","methodUrl":"/bao-yi-xx/add","operateType":"add","sort":2,"functionClassification":"0","_key":"2"},
{"name":"报溢信息获取编辑数据","methodUrl":"/bao-yi-xx/get-update/{id}","operateType":"search","sort":3,"functionClassification":"0","_key":"3"},
{"name":"报溢信息获取详情数据","methodUrl":"/bao-yi-xx/get-detail/{id}","operateType":"search","sort":4,"functionClassification":"0","_key":"4"},
{"name":"报溢信息编辑保存","methodUrl":"/bao-yi-xx/update","operateType":"edit","sort":5,"functionClassification":"0","_key":"5"},
{"name":"报溢信息删除","methodUrl":"/bao-yi-xx/delete/{id}","operateType":"delete","sort":6,"functionClassification":"0","_key":"6"},
{"name":"报溢信息批量删除","methodUrl":"/bao-yi-xx/delete-batch","operateType":"delete","sort":7,"functionClassification":"0","_key":"7"}
]
菜单识别:独立查询-query为入口
[
{"name":"报溢信息独立查询","methodUrl":"/bao-yi-xx/query","operateType":"search","sort":11,"functionClassification":"0","_key":"11"}
]
菜单识别:审核相关-audit-list为入口
[
{"name":"报溢信息审核列表","methodUrl":"/bao-yi-xx/audit-list","operateType":"search","sort":12,"functionClassification":"0","_key":"12"},
{"name":"报溢信息获取审核内容","methodUrl":"/bao-yi-xx/get-audit/{id}","operateType":"search","sort":13,"functionClassification":"0","_key":"13"},
{"name":"报溢信息审核","methodUrl":"/bao-yi-xx/audit","operateType":"edit","sort":14,"functionClassification":"0","_key":"14"}
]
*/
